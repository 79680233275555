<template>
   <div>
        <b-modal class="modalGiftCard" :id="id" size="lg" hide-footer>
            <template v-slot:modal-title>
                Una gift card da collezione e una elegante scatolina
            </template>
            
            <img src="@/assets/images/homepage-banner-giftcard.jpg" />
            
            <div class="modal-section">                
                <div class="panel panel-dark-blue large-radius">
                    <div class="panel-inner text-center">
                        <h4>
                            Nessun supplemento rispetto all'email voucher e spese di spedizione incluse
                        </h4>
                        <br>
                        <h5>
                            Approfitta dell'offerta di lancio!
                        </h5>
                    </div>
                </div>

                <div class="panel panel-primary large-radius mb-0">
                    <div class="panel-inner text-center">
                        <h4 class="white">
                            Come acquistare?
                        </h4>
                        <br>
                        <h5 class="white">
                            Seleziona l'opzione "&Egrave; un regalo" nella pagina di check-out.
                        </h5>
                    </div>
                </div>

                <br>

                <p class="text-right mb-0">
                    <small>
                    * Attenzione: non disponibile per acquisti con welfare aziendale
                    </small>
                </p>
            </div>
        </b-modal>       
   </div> 
</template>

<script>
export default {
    name: 'modal-gift-card',
    props: {
        id: {
            type: String,
            default: 'modalGiftCardsInfo'
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .modalGiftCard
    {
        .modal-content
        {
            background:#E3E3E3;
        }
        header
        {
            margin-bottom:0;
            background:#E3E3E3;
        }
      .modal-section
      {
        border:0;
        overflow:hidden;
      }
      img
      {
        width:100%;
      }
    }

    #modalGiftCardsInfo
    {
        .modal-header
        {
            margin-bottom:0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
</style>