var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "25",
        viewBox: "0 0 24 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.4985 6.57895C14.3152 6.57895 15.7879 5.1062 15.7879 3.28947C15.7879 1.47275 14.3152 0 12.4985 0C10.6817 0 9.20898 1.47275 9.20898 3.28947C9.20898 5.1062 10.6817 6.57895 12.4985 6.57895Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.501 24.9981C13.5911 24.9981 14.4747 24.1145 14.4747 23.0245C14.4747 21.9344 13.5911 21.0508 12.501 21.0508C11.411 21.0508 10.5273 21.9344 10.5273 23.0245C10.5273 24.1145 11.411 24.9981 12.501 24.9981Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.479 7.16509C20.024 7.16509 20.4659 6.72327 20.4659 6.17825C20.4659 5.63323 20.024 5.19141 19.479 5.19141C18.934 5.19141 18.4922 5.63323 18.4922 6.17825C18.4922 6.72327 18.934 7.16509 19.479 7.16509Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.52138 22.4373C6.79309 22.4373 7.82401 21.4064 7.82401 20.1347C7.82401 18.863 6.79309 17.832 5.52138 17.832C4.24967 17.832 3.21875 18.863 3.21875 20.1347C3.21875 21.4064 4.24967 22.4373 5.52138 22.4373Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.3705 14.4753C23.0972 14.4753 23.6863 13.8862 23.6863 13.1595C23.6863 12.4328 23.0972 11.8438 22.3705 11.8438C21.6438 11.8438 21.0547 12.4328 21.0547 13.1595C21.0547 13.8862 21.6438 14.4753 22.3705 14.4753Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.63158 15.7905C4.08496 15.7905 5.26316 14.6123 5.26316 13.1589C5.26316 11.7055 4.08496 10.5273 2.63158 10.5273C1.1782 10.5273 0 11.7055 0 13.1589C0 14.6123 1.1782 15.7905 2.63158 15.7905Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.4768 21.7817C20.3851 21.7817 21.1215 21.0453 21.1215 20.1369C21.1215 19.2286 20.3851 18.4922 19.4768 18.4922C18.5684 18.4922 17.832 19.2286 17.832 20.1369C17.832 21.0453 18.5684 21.7817 19.4768 21.7817Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.51912 9.1398C7.15417 9.1398 8.47965 7.81433 8.47965 6.17928C8.47965 4.54422 7.15417 3.21875 5.51912 3.21875C3.88407 3.21875 2.55859 4.54422 2.55859 6.17928C2.55859 7.81433 3.88407 9.1398 5.51912 9.1398Z",
          fill: "#5D4EBB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }