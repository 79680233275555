var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "btn-back" }, [
    _c("div", { staticClass: "btn-flex" }, [
      _c("div", [
        _c(
          "svg",
          {
            attrs: {
              width: "27",
              height: "20",
              viewBox: "0 0 27 20",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M25.4001 9H4.10009L11.1001 2C11.5001 1.6 11.5001 1 11.1001 0.600003C10.7001 0.200003 10.1001 0.200003 9.70012 0.600003L1.00011 9.3C0.90011 9.4 0.800098 9.5 0.800098 9.6C0.700098 9.8 0.700098 10.1 0.800098 10.4C0.900098 10.5 0.90011 10.6 1.00011 10.7L9.70012 19.4C9.90012 19.6 10.2001 19.7 10.4001 19.7C10.6001 19.7 10.9001 19.6 11.1001 19.4C11.5001 19 11.5001 18.4 11.1001 18L4.10009 11H25.4001C26.0001 11 26.4001 10.6 26.4001 10C26.4001 9.4 26.0001 9 25.4001 9Z",
                fill: "#0071BC",
              },
            }),
          ]
        ),
      ]),
      _c("span", [_vm._v(_vm._s(_vm.text))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }