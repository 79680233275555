var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "37",
        height: "20",
        viewBox: "0 0 37 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M3.40425 0.425523C3.40425 0.193414 3.21084 0 2.97873 0H0.425523C0.193414 0 0 0.193414 0 0.425523V19.5745C0 19.8066 0.193414 20 0.425523 20H2.97873C3.21084 20 3.40425 19.8066 3.40425 19.5745V15.8221H33.3076V19.5745C33.3076 19.8066 33.501 20 33.7331 20H36.2863C36.5184 20 36.7118 19.8066 36.7118 19.5745V12.4178H3.36558V0.425523H3.40425Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.82085 10.9464C11.0001 10.9464 12.7667 9.17977 12.7667 7.00054C12.7667 4.82131 11.0001 3.05469 8.82085 3.05469C6.64162 3.05469 4.875 4.82131 4.875 7.00054C4.875 9.17977 6.64162 10.9464 8.82085 10.9464Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M33.1157 6.23005L16.9068 2.74842C15.5141 2.43894 14.1602 3.52212 14.1602 4.95346V10.9496H36.7134V10.7175C36.7521 8.55113 35.2433 6.65558 33.1157 6.23005Z",
          fill: "#5D4EBB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }