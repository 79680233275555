var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticClass: "modalGiftCard",
          attrs: { id: _vm.id, size: "lg", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm._v(
                    "\n             Una gift card da collezione e una elegante scatolina\n         "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/homepage-banner-giftcard.jpg"),
            },
          }),
          _c("div", { staticClass: "modal-section" }, [
            _c("div", { staticClass: "panel panel-dark-blue large-radius" }, [
              _c("div", { staticClass: "panel-inner text-center" }, [
                _c("h4", [
                  _vm._v(
                    "\n                         Nessun supplemento rispetto all'email voucher e spese di spedizione incluse\n                     "
                  ),
                ]),
                _c("br"),
                _c("h5", [
                  _vm._v(
                    "\n                         Approfitta dell'offerta di lancio!\n                     "
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "panel panel-primary large-radius mb-0" },
              [
                _c("div", { staticClass: "panel-inner text-center" }, [
                  _c("h4", { staticClass: "white" }, [
                    _vm._v(
                      "\n                         Come acquistare?\n                     "
                    ),
                  ]),
                  _c("br"),
                  _c("h5", { staticClass: "white" }, [
                    _vm._v(
                      '\n                         Seleziona l\'opzione "È un regalo" nella pagina di check-out.\n                     '
                    ),
                  ]),
                ]),
              ]
            ),
            _c("br"),
            _c("p", { staticClass: "text-right mb-0" }, [
              _c("small", [
                _vm._v(
                  "\n                 * Attenzione: non disponibile per acquisti con welfare aziendale\n                 "
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }